import { Box, Flex, Heading } from '@chakra-ui/react';
import React, { useEffect } from 'react';

import config from 'configs/app';
import ChainIndicators from 'ui/home/indicators/ChainIndicators';
import LatestBlocks from 'ui/home/LatestBlocks';
import LatestZkEvmL2Batches from 'ui/home/LatestZkEvmL2Batches';
import Stats from 'ui/home/Stats';
import Transactions from 'ui/home/Transactions';
import AdBanner from 'ui/shared/ad/AdBanner';
import * as Layout from 'ui/shared/layout/components';
import ProfileMenuDesktop from 'ui/snippets/profileMenu/ProfileMenuDesktop';
import SearchBar from 'ui/snippets/searchBar/SearchBar';
import WalletMenuDesktop from 'ui/snippets/walletMenu/WalletMenuDesktop';

const rollupFeature = config.features.rollup;

const Home = () => {
  useEffect(() => {
    setTimeout(() => {
      const particles = document.createElement('script');
      particles.src = '/assets/js/particles/particles.min.js'; // URL of the first script
      particles.onload = function() {
        // This code runs after the first script is loaded
        const code = document.createElement('script');
        code.src = '/assets/js/particles/script.js'; // URL of the second script
        document.body.appendChild(code);
      };
      document.body.appendChild(particles);
    }, 2000);
  }, []);
  return (
    <>
      <script src="/assets/js/particles/particles.min.js" async></script>
      <Box
        flexGrow={ 1 }
        style={{
          position: 'relative',
          width: 'calc(100% + 3rem)',
          height: '280px',
          overflow: 'hidden',
          marginLeft: '-1.5rem',
        }}
        className="homepage-particle-container"
      >
        <div
          id="particles-js"
          style={{
            height: '300px',
            left: 0,
            position: 'absolute',
            width: '100%',
          }}
        ></div>
        <Flex
          w="100%"
          h="100%"
          background={ config.UI.homepage.plate.background }
          backgroundSize="cover"
          backgroundPosition="center"
          borderRadius="0"
          p={{ base: 0 }}
          columnGap={ 8 }
          alignItems="center"
          data-label="hero plate"
          className="jumbotron"
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <Box w="100%" h="100%"/>
          <Box
            flexGrow={ 1 }
            maxWidth={{ md: '100%', lg: '1440px' }}
            margin="0 auto"
            w={{ base: 'unset', md: '100%' }}
            h="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            zIndex={ 2 }
          >
            <Flex
              mb={{ base: 2, lg: 3 }}
              justifyContent="space-between"
              alignItems="center"
              columnGap={ 2 }
            >
              <Heading
                as="h1"
                fontSize={{ base: '18px', lg: '30px' }}
                lineHeight={{ base: '24px', lg: '36px' }}
                fontWeight={{ base: 500, lg: 700 }}
                color={ config.UI.homepage.plate.textColor }
              >
                { config.meta.seo.enhancedDataEnabled ?
                  `${ config.chain.name } blockchain Explorer` :
                  `${ config.chain.name } Explorer` }
              </Heading>
              { config.UI.navigation.layout === 'vertical' && (
                <Box display={{ base: 'none', lg: 'flex' }}>
                  { config.features.account.isEnabled && (
                    <ProfileMenuDesktop isHomePage/>
                  ) }
                  { config.features.blockchainInteraction.isEnabled && (
                    <WalletMenuDesktop isHomePage/>
                  ) }
                </Box>
              ) }
            </Flex>
            <SearchBar isHomepage/>
          </Box>
          <AdBanner
            platform="mobile"
            w="fit-content"
            flexShrink={ 0 }
            borderRadius="md"
            overflow="hidden"
          />
        </Flex>
      </Box>
      <Layout.MainArea mt={ 6 }>
        <Box as="main">
          <Flex
            flexDir={{ base: 'column', lg: 'row' }}
            mt={ -14 }
            position="relative"
            zIndex={ 1 }
            _empty={{ mt: 0 }}
            className="white-shadow-container"
          >
            <Stats/>
            <ChainIndicators/>
          </Flex>
          <AdBanner mt={ 6 } mx="auto" display="flex" justifyContent="center"/>
          <Flex
            mt={ 8 }
            direction={{ base: 'column', lg: 'row' }}
            columnGap={ 6 }
            rowGap={ 6 }
            className="home-latest-container"
          >
            { rollupFeature.isEnabled && rollupFeature.type === 'zkEvm' ? (
              <LatestZkEvmL2Batches/>
            ) : (
              <LatestBlocks/>
            ) }
            <Box flexGrow={ 1 } className="white-shadow-container">
              <Transactions/>
            </Box>
          </Flex>
        </Box>
      </Layout.MainArea>
    </>
  );
};

export default Home;
